import React, { useMemo } from 'react';
import { Table } from 'components/ui';
import { useTable } from 'react-table';

const { Tr, Th, Td, THead, TBody } = Table;

const CTable = ({ columns = [], tableData = [], updateMyData }) => {
    const tableClumns = useMemo(() => columns, [columns]);

    const { getTableProps, getTableBodyProps, prepareRow, headerGroups, rows } = useTable({
        columns: tableClumns,
        data: tableData,
        initialState: { pageIndex: 0 },
        updateMyData
    });

    return (
        <>
            <Table compact {...getTableProps()}>
                <THead>
                    {headerGroups.map(headerGroup => (
                        <Tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <Th {...column.getHeaderProps()}>{column.render('Header')}</Th>
                            ))}
                        </Tr>
                    ))}
                </THead>
                <TBody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <Tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>;
                                })}
                            </Tr>
                        );
                    })}
                </TBody>
            </Table>
        </>
    );
};

export default CTable;
