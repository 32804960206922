const appConfig = {
    apiPrefix: 'http://106.10.33.57:8080/api',
    // apiPrefix: 'http://127.0.0.1:8888/api',
    // apiPrefix: 'http://api.cloucode.com:3010/api',
    authenticatedEntryPath: '/home',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'ko',
    enableMock: false
};

export default appConfig;
