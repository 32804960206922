import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './lang/en.json';
import appConfig from 'configs/app.config';

const resources = {
    en: {
        translation: en
    },
    ko: {
        translation: en
    }
};

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: appConfig.locale,
    lng: appConfig.locale,
    interpolation: {
        escapeValue: false
    }
});

export const dateLocales = {
    en: () => import('dayjs/locale/en'),
    ko: () => import('dayjs/locale/ko')
};

export default i18n;
